import { AspectRatio, Box, Button, ChakraProps, Flex, Text } from '@chakra-ui/react';
import { LinkType } from 'lib/enums/LinkType';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';
import { OverviewItemModel } from 'utils/types/overviewItemModel';

import { Link as CustomLink } from '../base/Link';
import { Tag, tags, TagType } from '../base/Tag';
// import { hrefLink } from 'utils/normalization/hrefLink';
// import { withTranslations } from 'store/translations';
import { HyphenResult } from '../hyphen/hyphenation';

interface OverviewItemProps {
  model: OverviewItemModel;
  singleItem?: boolean;
  imageSx?: ChakraProps;
  numberOfItems?: number;
}

export const OverviewItem: FunctionComponent<OverviewItemProps> = ({ model, singleItem, numberOfItems }) => {
  //  const router = useRouter();
  const router = useRouter();
  // const languageArray = ['da-dk', 'fi-fi', 'de-de', 'no-no', 'sv-se', 'en-apac', 'en-us', 'en-gb'];
  // languageArray.forEach((lang) => {
  //   const link = model.link?.split('/');
  //   if(router.locale === lang) {
  //     console.log("index",link?.indexOf(lang))
  //     let arr = link?.splice(link?.indexOf(lang),1)
  //     console.log("arr",arr,arr?.join("/"))

  //   }
  // });
  // const translations = withTranslations();
  // const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  // const url = hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  return (
    <Box
      display='flex'
      flexDir={
        singleItem
          ? { base: 'column-reverse', lg: model.mediaPosition === 'end' ? 'row' : 'row-reverse' }
          : 'column-reverse'
      }
      w={{ base: numberOfItems === 3 ? '71.2vw' : 'unset', lg: 'unset' }}
      minW={{ base: '71.2vw', lg: '200px' }}
      h='100%'
      justifyContent={'start'}
    >
      <Flex
        ml={0}
        flexDir={'column'}
        maxWidth={singleItem ? { lg: '32.8125%' } : ''}
        minWidth={singleItem ? { lg: '32.8125%' } : ''}
        mt={{ base: model.metadata && !singleItem ? 'xxs' : '0px', lg: '0px' }}
        wordBreak='break-word'
      >
        {/* Headline block start */}

        {model.link && (
          // <CustomLink href={model.link} type={LinkType.Item} locale={router.locale}>
          <CustomLink
            ariaLabel={model.ariaLinkText}
            href={'/[...slug].tsx'}
            as={`${model.link}`}
            type={LinkType.Item}
            // iconName='chevronRightForLink'
          >
            <Text
              as='h3'
              textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
              pb={{ base: 'xs' }}
              dangerouslySetInnerHTML={{ __html: model.headline ? HyphenResult(model.headline, router.locale) : '' }}
            ></Text>
          </CustomLink>
        )}
        {model.externalUrl && (
          // <CustomLink href={model.externalUrl} type={LinkType.Item} locale={router.locale}>
          <CustomLink
            href={model.externalUrl}
            type={LinkType.Item}
            // download={model.downloadLink}
            // iconName='chevronRightForLink'
            isExternal={model.linkOpenInNewTab}
          >
            <Text
              as='h3'
              textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
              pb={{ base: 'xs' }}
              dangerouslySetInnerHTML={{ __html: model.headline ? HyphenResult(model.headline, router.locale) : '' }}
            ></Text>
          </CustomLink>
        )}
        {model.downloadLink && (
          // <CustomLink href={model.downloadLink} type={LinkType.Item} locale={router.locale}>
          <CustomLink
            href={model.downloadLink}
            type={LinkType.Item}
            download={model.downloadLink}
            // iconName='chevronRightForLink'
            isExternal={model.linkOpenInNewTab}
          >
            <Text
              as='h3'
              textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
              pb={{ base: 'xs' }}
              dangerouslySetInnerHTML={{ __html: model.headline ? HyphenResult(model.headline, router.locale) : '' }}
            ></Text>
          </CustomLink>
        )}
        {!model.link && !model.externalUrl && !model.downloadLink && (
          <Text
            as='h3'
            textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
            pb={{ base: 'xs' }}
            dangerouslySetInnerHTML={{ __html: model.headline ? HyphenResult(model.headline, router.locale) : '' }}
          ></Text>
        )}
        {/* Headline block end */}

        <Flex flexDirection={'column'} mt={singleItem ? 's' : ''}>
          <>
            {/* Description block start */}

            {model.text?.map((text, index) => {
              return (
                model.link && (
                  // <CustomLink href={model.link} type={LinkType.Item} locale={router.locale}>
                  <CustomLink
                    ariaLabel={model.ariaLinkText}
                    href={'/[...slug].tsx'}
                    as={`${model.link}`}
                    type={LinkType.Item}
                    // iconName='chevronRightForLink'
                  >
                    <Text
                      key={index}
                      mb={model.text && index === model.text.length - 1 ? 'xs' : 0}
                      textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                      dangerouslySetInnerHTML={{ __html: text.body }}
                    />
                  </CustomLink>
                )
              );
            })}
            {model.text?.map((text, index) => {
              return (
                model.externalUrl && (
                  // <CustomLink href={model.externalUrl} type={LinkType.Item} locale={router.locale}>
                  <CustomLink
                    href={model.externalUrl}
                    type={LinkType.Item}
                    // download={model.downloadLink}
                    // iconName='chevronRightForLink'
                    isExternal={model.linkOpenInNewTab}
                  >
                    <Text
                      key={index}
                      mb={model.text && index === model.text.length - 1 ? 'xs' : 0}
                      textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                      dangerouslySetInnerHTML={{ __html: text.body }}
                    />
                  </CustomLink>
                )
              );
            })}
            {model.text?.map((text, index) => {
              return (
                model.downloadLink && (
                  // <CustomLink href={model.downloadLink} type={LinkType.Item} locale={router.locale}>
                  <CustomLink
                    href={model.downloadLink}
                    type={LinkType.Item}
                    download={model.downloadLink}
                    // iconName='chevronRightForLink'
                    isExternal={model.linkOpenInNewTab}
                  >
                    <Text
                      key={index}
                      mb={model.text && index === model.text.length - 1 ? 'xs' : 0}
                      textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                      dangerouslySetInnerHTML={{ __html: text.body }}
                    />
                  </CustomLink>
                )
              );
            })}
            {!model.link &&
              !model.externalUrl &&
              !model.downloadLink &&
              model.text?.map((text, index) => (
                <Text
                  key={index}
                  mb={model.text && index === model.text.length - 1 ? 'xs' : 0}
                  textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                  dangerouslySetInnerHTML={{ __html: text.body }}
                />
              ))}
            {/* Description block end */}

            {/* ))} */}
            {/* CTA block start */}

            {model.link && model.linkText && model.ctaSnippetStyle != 'button' && (
              // <CustomLink
              //   ariaLabel={model.ariaLinkText}
              //   href={model.link}
              //   type={LinkType.SecondaryButton}
              //   iconName='chevronRightForLink'
              //   locale={router.locale}
              // >
              <CustomLink
                ariaLabel={model.ariaLinkText}
                href={'/[...slug].tsx'}
                as={`${model.link}`}
                type={LinkType.SecondaryButton}
                iconName='chevronRightForLink'
              >
                {model.linkText}
              </CustomLink>
            )}
            {model.link && model.linkText && model.ctaSnippetStyle == 'button' && (
              <Button as='a' target={model.linkOpenInNewTab ? '_blank' : ''} href={model.externalUrl}>
                {model.linkText}
              </Button>
            )}
            {model.downloadLink && model.linkText && model.ctaSnippetStyle != 'button' && (
              // <CustomLink
              //   href={model.downloadLink}
              //   type={LinkType.SecondaryButton}
              //   download={model.downloadLink}
              //   iconName='chevronRightForLink'
              //   isExternal={model.linkOpenInNewTab}
              //   locale={router.locale}
              // >
              <CustomLink
                href={model.downloadLink}
                type={LinkType.SecondaryButton}
                download={model.downloadLink}
                iconName='chevronRightForLink'
                isExternal={model.linkOpenInNewTab}
              >
                {model.linkText}
              </CustomLink>
            )}
            {model.downloadLink && model.linkText && model.ctaSnippetStyle == 'button' && (
              <Button
                as='a'
                target={model.linkOpenInNewTab ? '_blank' : ''}
                href={model.downloadLink}
                download={model.downloadLink}
              >
                {model.linkText}
              </Button>
            )}
            {model.externalUrl && model.linkText && model.ctaSnippetStyle != 'button' && (
              // <CustomLink
              //   href={model.externalUrl}
              //   type={LinkType.SecondaryButton}
              //   iconName='chevronRightForLink'
              //   isExternal={model.linkOpenInNewTab}
              //   locale={router.locale}
              // >
              <CustomLink
                href={model.externalUrl}
                type={LinkType.SecondaryButton}
                // download={model.downloadLink}
                iconName='chevronRightForLink'
                isExternal={model.linkOpenInNewTab}
              >
                {model.linkText}
              </CustomLink>
            )}
            {model.externalUrl && model.linkText && model.ctaSnippetStyle == 'button' && (
              <Button as='a' target={model.linkOpenInNewTab ? '_blank' : ''} href={model.externalUrl}>
                {model.linkText}
              </Button>
            )}
            {/* CTA block end */}
          </>
        </Flex>
      </Flex>
      {/* Metadata/Feature/tag */}

      {model.metadata && !singleItem && (
        <Text suppressHydrationWarning mb={{ base: '0px', md: 'xxs' }} textStyle='desktop.body.book.s'>
          {model.metadata}
        </Text>
      )}
      {/* Image block start */}
      {model.image && (
        <Box
          w='100%'
          position='relative'
          sx={
            singleItem
              ? model.mediaPosition === 'start'
                ? { mr: { lg: 'l' } }
                : { ml: { lg: 'l' } }
              : { mb: { base: 'xs', lg: model.metadata ? 'xs' : 's' } }
          }
        >
          {/* <Link
              href={model.link ? model.link : model.externalUrl ? model.externalUrl : model.downloadLink}
            > */}
          {model.link && (
            <AspectRatio ratio={16 / 9} maxW='1276px' overflow='hidden' mt={{ base: 's', md: 'l' }}>
              {/* <CustomLink href={`${model.link}`} type={LinkType.Text} locale={router.locale}> */}
              <CustomLink
                ariaLabel={model.ariaLinkText}
                href={'/[...slug].tsx'}
                as={`${model.link}`}
                type={LinkType.Item}
                // iconName='chevronRightForLink'
              >
                <Image
                  // style={{ objectFit: 'cover' }}
                  width={model.image.width}
                  height={model.image.height}
                  src={model.image.src}
                  alt={model.image.alt}
                  quality={model.image.quality}
                ></Image>
              </CustomLink>
            </AspectRatio>
          )}
          {model.externalUrl && (
            <AspectRatio ratio={16 / 9} maxW='1276px' overflow='hidden' mt={{ base: 's', md: 'l' }}>
              {/* <CustomLink href={`${model.externalUrl}`} type={LinkType.Text} locale={router.locale}> */}
              <CustomLink
                href={model.externalUrl}
                type={LinkType.Item}
                // download={model.downloadLink}
                // iconName='chevronRightForLink'
                isExternal={model.linkOpenInNewTab}
              >
                <Image
                  // style={{ objectFit: 'cover' }}
                  width={model.image.width}
                  height={model.image.height}
                  src={model.image.src}
                  alt={model.image.alt}
                  quality={model.image.quality}
                ></Image>
              </CustomLink>
            </AspectRatio>
          )}
          {model.downloadLink && (
            <AspectRatio ratio={16 / 9} maxW='1276px' overflow='hidden' mt={{ base: 's', md: 'l' }}>
              {/* <CustomLink href={`${model.externalUrl}`} type={LinkType.Text} locale={router.locale}> */}
              <CustomLink
                href={model.downloadLink}
                type={LinkType.Item}
                // download={model.downloadLink}
                // iconName='chevronRightForLink'
                isExternal={model.linkOpenInNewTab}
              >
                <Image
                  // style={{ objectFit: 'cover' }}
                  width={model.image.width}
                  height={model.image.height}
                  src={model.image.src}
                  alt={model.image.alt}
                  quality={model.image.quality}
                ></Image>
              </CustomLink>
            </AspectRatio>
          )}
          {/* </Link> */}

          {/* Image block end */}
          {(model.featured || model.mediaType) && !singleItem && (
            <Flex wrap='wrap' justifyContent='end' gap='xxs' position='absolute' right='xs' bottom='xs'>
              {model.mediaType && tags.includes(model.mediaType) && model.link && (
                <a href={model.link}>
                  <Tag type={model.mediaType as TagType} />
                </a>
              )}
              {model.featured && <Tag type='featured' />}
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};
